<template>
  <div class="wrapper">
    <ctheader></ctheader>
    <div class="screen-manage">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="市场名称">
          <el-autocomplete
            v-model="market_name"
            :fetch-suggestions="querySearchAsync"
            placeholder="全部市场"
            @select="handleSelect"
            @input="inputHandle"
            clearable
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="档口名称/uuid">
          <el-input
            v-model="formInline.keyword"
            placeholder="档口名称/uuid"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="全部类型">
          <el-select
            v-model="formInline.screen_type"
            placeholder="全部类型"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in typeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="query" size="small">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="screen_list"
        style="width: 100%"
        :header-cell-style="getRowClass"
      >
        <el-table-column fixed prop="market_name" label="市场名称">
        </el-table-column>
        <el-table-column prop="uuid" label="uuid"> </el-table-column>
        <el-table-column label="设备类型">
          <template slot-scope="scope">
            {{
              scope.row.screen_type == 1
                ? "手机"
                : scope.row.screen_type == 2
                ? "PC"
                : scope.row.screen_type == 3
                ? "安卓"
                : scope.row.screen_type == 4
                ? "安卓石田"
                : scope.row.screen_type == 5
                ? "档口公示屏"
                : scope.row.screen_type == 6
                ? "市场导视屏"
                : scope.row.screen_type == 7
                ? "移动PDA"
                : scope.row.screen_type == 8
                ? "数据大屏"
                : scope.row.screen_type == 9
                ? "会员查询机"
                : "质检屏"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="device_type" label="系统设备"> </el-table-column>
      </el-table>
      <pagination
        :start="formInline.start"
        :total="screen_total"
        :page-length="formInline.length"
        :pageCallback="getScreenList"
      ></pagination>
    </div>
  </div>
</template>

<script>
import ctheader from "../../components/ctheader";
import { apiPost } from "@/modules/utils";
import APIs from "@/modules/APIs";
import pagination from "../../components/pagination";

export default {
  components: { ctheader, pagination },
  data() {
    return {
      formInline: {
        action: "screen.list",
        start: 0,
        length: 10,
      },
      restaurants: [],
      timeout: null,
      marketForm: {
        start: 0,
        length: 10,
        action: "market.list",
        expiration_status: 0,
      },
      typeList: [
        {
          id: 2,
          name: "商户屏",
        },
        // {
        //   id: 8,
        //   name: "数据大屏",
        // },
        // {
        //   id: 6,
        //   name: "导视屏",
        // },
        // {
        //   id: 10,
        //   name: "质检屏",
        // },
        // {
        //   id: 9,
        //   name: "查询机",
        // },
      ],
      screen_list: [],
      screen_total: 0,
      market_name: "",
    };
  },
  mounted() {
    this.getScreenList();
  },
  methods: {
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    handleSelect(val) {
      this.formInline.market_id = val.id;
    },
    inputHandle(val) {
      console.log(val);
      this.market_name = val;
      this.marketForm.market_name = this.market_name;
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: $this.marketForm,
        success(res) {
          if (res.code === "0") {
            $this.restaurants = res.data.map((item) => {
              return {
                value: item.market_name,
                id: item.id,
              };
            });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 500 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    //屏列表
    getScreenList(index = 0) {
      this.formInline.action = "screen.list";
      this.formInline.start = index;
      let $this = this;
      apiPost({
        url: APIs.SCREEN,
        data: $this.formInline,
        success(res) {
          if (res.code === "0") {
            $this.screen_list = res.data;
            $this.screen_total = res.total;
          } else {
            $this.screen_list = [];
            $this.screen_total = 0;
          }
        },
        error(res) {
          $this.dataList = [];
          $this.tableSet.total = 0;
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    query() {
      this.formInline.market_id = this.market_name
        ? this.formInline.market_id
        : "";
      this.formInline.start = 0;
      this.getScreenList();
    },
  },
};
</script>

<style lang="less">
.screen-manage {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  .el-select .el-input.is-focus .el-input__inner {
    border-color: rgb(37, 120, 115);
  }
  .el-input--small .el-input__inner:focus {
    border-color: #257873;
  }
  .el-button--primary {
    background: #257873;
    border-color: #257873;
  }
  .el-button--text {
    color: #257873;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: rgb(37, 120, 115);
  }
  .el-input--small .el-input__inner:focus {
    border-color: #257873;
  }
  .el-button--primary {
    background: #257873;
    border-color: #257873;
  }
  .el-button--text {
    color: #257873;
  }
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #257873 !important;
  border-color: #257873 !important;
}
.el-checkbox__inner:hover {
  border-color: #257873 !important;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: rgb(37, 120, 115);
}
.el-input--small .el-input__inner:focus {
  border-color: #257873;
}
.el-button--primary {
  background: #257873;
  border-color: #257873;
}
.el-button--primary:hover {
  background: #257873;
  border-color: #257873;
}
.el-select-dropdown__item.selected {
  color: #257873;
}
.el-button + .el-button {
  background: #257873;
  border-color: #257873;
  color: #fff;
}
</style>
